import type { FunctionalComponent } from 'preact';

import { BlockTimerBanner1Label } from '../__label/block-timer-banner-1__label.js';
import { BlockTimerBanner1Value } from '../__value/block-timer-banner-1__value.js';
import { cnBlockTimerBanner1 } from '../block-timer-banner-1.constants.js';

import './block-timer-banner-1__cell.css';

export interface BlockTimerBanner1CellProps {
    value: number;
    label: string;
}

export const BlockTimerBanner1Cell: FunctionalComponent<BlockTimerBanner1CellProps> = ({ value, label }) => (
    <div className={cnBlockTimerBanner1('cell')}>
        <BlockTimerBanner1Value>{String(value).padStart(2, '0')}</BlockTimerBanner1Value>
        <BlockTimerBanner1Label>{label}</BlockTimerBanner1Label>
    </div>
);
