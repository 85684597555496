import type { FunctionalComponent } from 'preact';

import { cnBlockTimerBanner1 } from '../block-timer-banner-1.constants.js';

import './block-timer-banner-1__close-button.css';

export interface BlockTimerBanner1CloseButtonProps {
    onClick: VoidFunction;
    title: string;
}

export const BlockTimerBanner1CloseButton: FunctionalComponent<BlockTimerBanner1CloseButtonProps> = ({
    title,
    onClick,
}) => (
    <button
        onClick={onClick}
        aria-label={title}
        title={title}
        type="button"
        className={cnBlockTimerBanner1('close-button')}
    >
        <svg width="12" height="12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.32 2.613a.5.5 0 0 0-.707.707L5.293 6l-2.68 2.68a.5.5 0 0 0 .707.707L6 6.707l2.68 2.68a.5.5 0 1 0 .707-.707L6.707 6l2.68-2.68a.5.5 0 1 0-.707-.707L6 5.293l-2.68-2.68Z"
                fill="currentColor"
            />
        </svg>
    </button>
);
