import type { ComponentChildren, FunctionalComponent } from 'preact';

import { cnBlockTimerBanner1 } from '../block-timer-banner-1.constants.js';

import './block-timer-banner-1__value.css';

export interface BlockTimerBanner1ValueProps {
    children?: ComponentChildren;
}

export const BlockTimerBanner1Value: FunctionalComponent<BlockTimerBanner1ValueProps> = ({ children }) => (
    <div className={cnBlockTimerBanner1('value')}>{children}</div>
);
