import type { ComponentChildren, FunctionalComponent } from 'preact';

import { cnBlockTimerBanner1 } from '../block-timer-banner-1.constants.js';

import './block-timer-banner-1__button.css';

import './_color/block-timer-banner-1__button_color_green.css';

export interface BlockTimerBanner1ButtonProps {
    children?: ComponentChildren;
    color: 'green' | 'orange' | undefined;
    link?: string;
    onClick?: VoidFunction;
}

export const BlockTimerBanner1Button: FunctionalComponent<BlockTimerBanner1ButtonProps> = ({
    children,
    color,
    link,
}) => (
    <a
        id="timer-banner-1-button"
        href={link}
        target={link?.startsWith('#') ? undefined : '_blank'}
        rel="nofollow noreferrer"
        className={cnBlockTimerBanner1('button', { color })}
    >
        {children}
    </a>
);
