import type { ComponentChildren, FunctionalComponent } from 'preact';

import { cnBlockTimerBanner1 } from '../block-timer-banner-1.constants.js';

import './block-timer-banner-1__countdown.css';

export interface BlockTimerBanner1CountdownProps {
    children?: ComponentChildren;
}

export const BlockTimerBanner1Countdown: FunctionalComponent<BlockTimerBanner1CountdownProps> = ({ children }) => (
    <div className={cnBlockTimerBanner1('countdown')}>{children}</div>
);
