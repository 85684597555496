import type { ComponentChildren, FunctionalComponent } from 'preact';

import { cnBlockTimerBanner1 } from '../block-timer-banner-1.constants.js';

import './block-timer-banner-1__label.css';

export interface BlockTimerBanner1LabelProps {
    children?: ComponentChildren;
}

export const BlockTimerBanner1Label: FunctionalComponent<BlockTimerBanner1LabelProps> = ({ children }) => (
    <div className={cnBlockTimerBanner1('label')}>{children}</div>
);
