import { useEffect, useState } from 'preact/hooks';

const THRESHOLD_PX = 100;

export const useScrollReachedEnd = () => {
    const [isReached, setIsReached] = useState(true);

    useEffect(() => {
        const checkIsReached = () => {
            setIsReached(document.body.offsetHeight - (window.innerHeight + window.scrollY) < THRESHOLD_PX);
        };

        window.addEventListener('scroll', checkIsReached);
        window.addEventListener('resize', checkIsReached);

        checkIsReached();

        return () => {
            window.removeEventListener('scroll', checkIsReached);
            window.removeEventListener('resize', checkIsReached);
        };
    }, []);

    return isReached;
};
