export const getRemainingSecs = (date: string | undefined) => {
    if (!date) {
        return 0;
    }

    const dateSecs = Math.floor(new Date(date).getTime() / 1000);
    const secondsNow = Math.floor(Date.now() / 1000);

    return dateSecs - secondsNow;
};
