import { useEffect, useState } from 'preact/hooks';

import { getRemainingSecs } from '../block-timer-banner-1.utils/get-remaining-secs.js';

export const useRemainingTime = (dateEnd: string | undefined) => {
    const [secs, setSecs] = useState(() => getRemainingSecs(dateEnd));

    useEffect(() => {
        if (!dateEnd) {
            return undefined;
        }

        const interval = setInterval(() => setSecs(getRemainingSecs(dateEnd)), 1000);

        return () => clearInterval(interval);
    }, [dateEnd]);

    return {
        days: Math.floor(secs / 86400),
        hours: Math.floor(secs / 3600) % 24,
        mins: Math.floor(secs / 60) % 60,
        secs: secs % 60,
        ended: secs <= 0,
    };
};
