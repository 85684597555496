import type { ComponentChildren, FunctionalComponent } from 'preact';

import { cnBlockTimerBanner1 } from '../block-timer-banner-1.constants.js';

import './block-timer-banner-1__text.css';

export interface BlockTimerBanner1TextProps {
    children?: ComponentChildren;
}

export const BlockTimerBanner1Text: FunctionalComponent<BlockTimerBanner1TextProps> = ({ children }) => (
    <p className={cnBlockTimerBanner1('text')}>{children}</p>
);
