import type { FunctionalComponent } from 'preact';
import { useCallback, useState } from 'preact/hooks';

import { useRemainingTime } from './block-timer-banner-1.hooks/use-remaining-time.js';
import { useScrollReachedEnd } from './block-timer-banner-1.hooks/use-scroll-reached-end.js';
import type { BlockTimerBanner1Props } from './block-timer-banner-1.js';
import { BlockTimerBanner1 } from './block-timer-banner-1.js';

export type BlockTimerBanner1ContainerProps = Omit<
    BlockTimerBanner1Props,
    'hidden' | 'days' | 'hours' | 'mins' | 'secs' | 'onCloseButtonClick'
> & {
    endDate: string | undefined;
};

export const BlockTimerBanner1Container: FunctionalComponent<BlockTimerBanner1ContainerProps> = ({
    endDate,
    ...props
}) => {
    const { days, hours, mins, secs, ended } = useRemainingTime(endDate);
    const [closed, setClosed] = useState(false);
    const close = useCallback(() => setClosed(true), []);
    const reachedEnd = useScrollReachedEnd();

    return (
        <BlockTimerBanner1
            hidden={ended || closed || days >= 10 || reachedEnd}
            days={days}
            hours={hours}
            mins={mins}
            secs={secs}
            onCloseButtonClick={close}
            {...props}
        />
    );
};
